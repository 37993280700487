<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <agency-profile-left-section class="hidden-sm-and-down" />
    <agency-profile-needs-section />
  </v-container>
</template>
<script>
import AgencyProfileLeftSection from "./AgencyProfileLeftSection";
import AgencyProfileNeedsSection from "./AgencyProfileNeedsSection.vue";
export default {
  components: { AgencyProfileNeedsSection, AgencyProfileLeftSection },
};
</script>
